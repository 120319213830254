<template>
  <div>
    <div class="top-contain">
      <div class="Top_box">
        <div class="tow">
          <div>
            <span>专题学习：</span>
            <el-select class="choose" v-model="theme" placeholder="请选择">
              <el-option
                v-for="item in themeList"
                :key="item.theme"
                :label="item.theme"
                :value="item.theme"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            type="primary"
            @click="goThemesStudy"
            style="margin-left: 10px"
          >
            学习
          </el-button>
        </div>
        <div class="link">
          <div class="text" @click="$router.push('./assignStudy')">
            <img src="../../assets/images/img1/special1.png" />
          </div>
          <p>指派</p>
          <div class="text" @click="toTest">
            <img src="../../assets/images/img1/special2.png" />
          </div>
          <p>考试</p>
        </div>
      </div>
    </div>

    <div class="table-contain">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        height="500"
        style="width: 80%"
        border
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="interpretation" label="应知应会">
        </el-table-column>
        <el-table-column
          prop="lean"
          label="状态"
          width="200"
          v-if="!AssignToThis"
        >
          <template slot-scope="scope">
            <el-button
              @click="goStudy(scope.row)"
              type="primary"
              v-if="scope.row.status == 0"
              >未学习</el-button
            >
            <span v-if="scope.row.status == 1" style="color: #388ff2"
              >已学习</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="lean" label="状态" width="200" v-else>
          <template slot-scope="scope">
            <el-button
              @click="goAssStudy(scope.row)"
              type="primary"
              v-if="scope.row.status == 0"
              >未学习</el-button
            >
            <span v-else style="color: #388ff2">已学习</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        style="left: 10px"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="重新学习"
      :visible.sync="fileDialog"
      width="47"
      top="25vh"
    >
      <div class="file-dialog">
        <p v-if="!showFile">
          您已经完成文件学习！如需重新学习，请点击<el-button
            type="primary"
            @click="showFile = true"
            >重新学习</el-button
          >
        </p>
      </div>
      <span slot="footer">
        <el-button type="primary" v-if="showFile" @click="handleReqData"
          >确 定
        </el-button>
        <el-button @click="handleClose"> 取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="抽题"
      :visible.sync="drawDialog"
      width="30%"
      top="25vh"
      class="draw-dialog"
    >
      <div class="inp">
        <span>共 {{ this.count }} 道题，抽取题目数</span
        ><el-button
          class="btn"
          @click="handleDrawnNumber(0)"
          disabled
          icon="el-icon-minus"
        ></el-button
        ><input
          disabled
          type="text"
          v-model="drawncount"
          @input="inputInp"
        /><el-button
          class="btn"
          @click="handleDrawnNumber(1)"
          disabled
          icon="el-icon-plus"
        ></el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deawQuestion">确定</el-button>
        <el-button @click="drawDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from '../../api/http'

export default {
  data() {
    return {
      tableData: [],
      AssignToThis: null, //指派学习
      //  下拉框
      themeList: [],
      theme: '',
      // 抽题
      count: 0,
      drawncount: 0,
      drawDialog: false,
      // 分页
      start: 0,
      end: 10,
      currentPage1: 1,
      curSize: 10,
      // 重新学习
      fileDialog: false,
      showFile: false,
      noCheckFileList: [],
      getNum: 0,
    }
  },
  watch: {
    $route(to, v) {
      if (v.path == '/assignStudy' && to.path == '/special-subject') {
        this.AssignToThis = true
        this.theme = this.$route.query.theme
        this.getAssignThemeStudyRecord(this.$route.query.AssId)
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != 'test' && from.name != 'score') {
      to.meta.ifDoFresh = true
    }
    next()
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false
      this.theme = ''
      this.tableData = []
      this.getThemeList()
      //指派第一次到这里是触发
      if (this.$route.query.AssId) {
        this.AssignToThis = true
        this.getAssignThemeStudyRecord(this.$route.query.AssId)
      }
    }
  },
  methods: {
    getThemeList() {
      get('/api/SettingTheme/GetThemeMonth').then((resp) => {
        if (resp.code == 200) {
          this.themeList = resp.data
        }
      })
    },
    getAssignStudyRecord(e, v, t, m) {
      get(
        '/api/AssignStudyRecord/GetPostCount?CompId=' +
          e +
          '&DeptId=' +
          v +
          '&PostId=' +
          t
      ).then((res) => {
        this.count = res.count
        this.drawncount = res.count
        //this.drawDialog = true
        this.AssignDetail(e, v, t, m)
      })
    },
    AssignDetail(e, v, t, m) {
      get('api/AssignStudyRecord/GetByAssignId?AssignId=' + m).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data
          this.drawDialog = false
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    // 抽题
    goThemesStudy() {
      get(
        '/api/ThemesStudyList/' +
          JSON.parse(sessionStorage.getItem('userInfo')).userNo +
          '?themesName=' +
          this.theme
      ).then((res) => {
        if (res.code == 200) {
          if (res.data.length != 0) {
            this.$confirm('您还有未完成的学习题目，是否继续学习?')
              .then(() => {
                this.drawDialog = false
                this.tableData = res.data
              })
              .catch(() => {
                this.getCount()
              })
          } else {
            this.getCount()
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getCount() {
      get('/api/ThemeStudyRecord/GetThemeCount?Theme=' + this.theme).then(
        (resp) => {
          if (resp.code == 200) {
            this.count = resp.count
            this.drawncount = resp.count
            this.drawDialog = true
          } else {
            this.$message.error(resp.message)
          }
        }
      )
    },
    afreshStudy() {},
    deawQuestion() {
      post(
        '/api/ThemeStudyRecord/GetQuesByRandom?QuesNo=' +
          this.drawncount +
          '&Theme=' +
          this.theme
      )
        .then((resp) => {
          if (resp.code == 200) {
            if (
              resp.message == '您已经完成文件学习！如需重新学习，请点击学习'
            ) {
              this.fileDialog = true
              return
            }
            resp.data.map((item) => {
              item.lean = '未学习'
            })
            this.tableData = resp.data
            this.drawDialog = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('获取失败')
        })
    },
    handleDrawnNumber(i) {
      if (this.count == 0) {
        this.$message({
          type: 'warning',
          message: '暂无抽题数',
        })
        return
      }
      if (
        (this.count == 0 && i == 0) ||
        (this.drawncount == this.count && i == 1)
      ) {
        this.$message({
          type: 'warning',
          message: '已超出范围',
        })
        return
      }
      this.drawncount = i == 1 ? this.drawncount + 1 : this.drawncount - 1
    },
    inputInp() {
      if (this.drawncount > this.count) this.drawncount = 0
      if (this.drawncount < 0) this.drawncount = 0
    },
    // 学习
    pushJsonItem(item, list, i) {
      item = {
        fileNo: i.fileNo,
        fileName: i.fileName,
      }
      if (list.length == 0) {
        list.push(JSON.stringify(item))
      } else {
        list = list + ',' + JSON.stringify(item)
      }
    },
    goAssStudy(row) {
      put('/api/AssignThemeStudyRecord/' + row.id).then((res) => {
        if (res.code == '200') {
          row.status = 1
        } else {
          this.$message({
            type: 'warning',
            message: res.message,
          })
        }
      })
    },
    goStudy(row) {
      put('/api/ThemeStudyRecord/' + Number(row.id))
        .then((resp) => {
          if (resp.code == 200) {
            row.status = 1
          } else {
            this.message.error(resp.message)
          }
        })
        .catch(() => {
          this.message.error('系统异常，请稍后再试')
        })
    },
    getAssignThemeStudyRecord(id) {
      get('/api/AssignThemeStudyRecord/GetByAssignId?AssignId=' + id).then(
        (res) => {
          if (res.data.length == 0) {
            if (this.getNum == 0) this.reGet(id)
          } else {
            this.tableData = res.data
          }
        }
      )
    },
    reGet(id) {
      post('/api/AssignThemeStudyRecord/PostRandom?AssignId=' + id).then(() => {
        this.getNum += 1
        this.getAssignThemeStudyRecord(id)
      })
    },
    handleReqData() {
      post('/api/ThemeStudyRecord/PostReStudy?Theme=' + this.theme)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '开始重新学习',
            })
            this.fileDialog = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('重新学习失败')
        })
    },
    handleClose() {
      this.fileDialog = false
    },
    toTest() {
      if (!this.theme) {
        this.$message({
          type: 'warning',
          message: '未选择专题',
        })
        return
      }
      this.$router.push('./test?queryIndex=3&theme=' + this.theme)
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/style/Examine_CSS.css';
.top-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  .link {
    display: flex;
    align-items: center;
    & .text {
      background-color: transparent !important;
      color: #888686 !important;
      text-align: center;
      margin-left: 20px;
      img {
        width: 52px !important;
        height: 53px !important;
      }
    }
    p {
      margin-left: 5px;
    }
  }
}
.Top_box {
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  > div {
    margin-left: 15px;
  }
  .tow {
    display: flex;
    align-items: center;
  }
}
.table-contain {
  position: relative;
  background-color: #f5f6f8;
  padding: 10px;
  padding-bottom: 60px;
}
.file-dialog {
  text-align: center;
  > p {
    font-size: 14px;
    margin: 10px 0;
  }
  & .el-button {
    margin: 0 5px;
  }
  & .el-select {
    width: 600px !important;
  }
}
.draw-dialog {
  .inp {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 30px;
      font-size: 16px;
      display: inline-block;
      background-color: rgb(238, 237, 237);
      color: blue;
      margin: 0 4px;
      text-align: center;
    }
    input {
      width: 40px;
      border: 1px solid;
      height: 25px;
      border-color: #969696;
      text-align: center;
    }
  }
}
</style>
